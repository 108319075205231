import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Subject } from 'rxjs';

import { getAuthenticationTokens, removeAuthenticationTokens, setAuthenticationTokens } from '../../helpers';

export const onAuthenticationChange$ = new Subject();
export const onUserAvatarChange$ = new Subject();
export const onUserRoleChange$ = new Subject();
export const onLogoutChange$ = new Subject();
export const onEmailVerified$ = new Subject();

export const AuthenticationStore = {
	isLoggedIn: getAuthenticationTokens(true),

	login(tokens) {
		setAuthenticationTokens(tokens);
		this.isLoggedIn = true;
		onAuthenticationChange$.next(this.isLoggedIn);
	},

	logout(canLogout = true) {
		if (this.isLoggedIn && canLogout) {
			removeAuthenticationTokens();
			this.isLoggedIn = false;
			onAuthenticationChange$.next(this.isLoggedIn);
		}

		if (!canLogout) {
			onAuthenticationChange$.next(false);
		}
	}
};

export const AuthenticationRoute = ({ Component, path }) => (
	<Route
		path={path}
		render={() => (AuthenticationStore.isLoggedIn ? <Component /> : <Redirect to='/authentication/signin' />)}
	/>
);

AuthenticationRoute.propTypes = {
	path: PropTypes.string.isRequired,
	Component: PropTypes.elementType.isRequired
};
