import React from 'react';
import Router from './Routes';

function App() {
	return (
		<div className='app-container' id='cob-app-container'>
			<Router />
		</div>
	);
}

export default App;
