export const STORE_ACTIONS = {
	USER_INFO: 'user-info',
	ACCOUNT_INFO: 'account-info'
};

export const STORE_KEYS = {
	ACCOUNT_INFO: 'accountInfo',
	USER_INFO: 'userInfo',
	APP_GO_BACK_URL: 'appGoBackURL',
	NOTIFICATION_INFO: 'notificationInfo',
	CART_INFO: 'cart-info'
};
