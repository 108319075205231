import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { Icon } from '../icon/Icon';
import './page-not-found.scss';

export const PageNotFound = () => {
	// Component hooks instance declarations
	const history = useHistory();

	return ReactDOM.createPortal(
		<main className='cob-error-portal-page'>
			<div className='error-page-wrapper'>
				<div className='error-page-content'>
					<Icon name='404' src='404' width={400} height={300} />
					<h1 className='error-page-title'>404 - Page Not Found</h1>
					<h4 className='error-page-description'>
						The page you are looking for may have been deleted or is temporarily unavaiable
					</h4>
					<div className='error-page-actions'>
						<Button onClick={() => history.push('/home')}>Go Back To Home</Button>
					</div>
				</div>
			</div>
		</main>,
		document.getElementById('error-page-root')
	);
};
