import { createContext } from 'react';

import { STORE_KEYS } from './library/constants';

export const initialStore = {
	[STORE_KEYS.USER_INFO]: {
		FirstName: '',
		LastName: '',
		WorkEmail: '',
		Organization: '',
		UserRole: '',
		IsAccountAdmin: false,
		AdminChangeInvited: false,
		NewAdminInvited: '',
		ThirdPartyAuthentication: '',
		ImageUrl: '',
		UserState: '',
		Source: ''
	},
	[STORE_KEYS.APP_GO_BACK_URL]: ''
};

export const StoreContext = createContext({});
