import React from 'react';
import { notification } from 'antd';
import { Icon } from '../icon/Icon';
import './guidanz-toast.scss';

export const GuidanzToast = {
	success: options => success(options),
	error: options => error(options),
	warning: options => warning(options),
	info: options => info(options)
};

/* Success message toaster configuration */
const success = options => {
	GuidanzNotification('success', {
		message: 'Success',
		icon: <Icon name='successColour' />,
		...options
	});
};

/* Error message toaster configuration */
const error = options => {
	GuidanzNotification('error', {
		message: 'Error',
		icon: <Icon name='errorColour' />,
		...options
	});
};

/* Warning message toaster configuration */
const warning = options => {
	GuidanzNotification('error', {
		message: 'Warning',
		icon: <Icon name='warning' />,
		...options
	});
};

/* Information message toaster configuration */
const info = options => {
	GuidanzNotification('info', {
		message: 'Information',
		icon: <Icon name='infoToastColour' />,
		...options
	});
};

/* Method to hit ant design notification */
const GuidanzNotification = (type, config) => {
	const { placement = 'topRight', duration = 3, description, message, icon, key } = config;
	notification[type]({
		key,
		placement,
		duration,
		message,
		description,
		icon,
		className: 'guidanz-toast-notification'
	});
};
