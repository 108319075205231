import { Subject } from 'rxjs';
import { STORAGE } from '../constants';

export const onAutoLogout$ = new Subject();

/* Handler for session timeout */
const isSessionTimedOut = () => {
	const currentTime = new Date().getTime();
	if (
		localStorage.getItem(STORAGE.TOKEN_EXPIRY_TIMESTAMP) &&
		JSON.parse(localStorage.getItem(STORAGE.TOKEN_EXPIRY_TIMESTAMP)) <= currentTime
	) {
		onAutoLogout$.next();
		return false;
	}

	return true;
};

export const getAuthenticationTokens = (onInit = false) => {
	if (onInit) {
		setInterval(() => {
			isSessionTimedOut();
		}, 1000 * 60 * 60); // Interval to check session an every 1 hr
	}

	try {
		const sessionTimeout = isSessionTimedOut();

		if (localStorage.getItem(STORAGE.AUTHENTICATION_TOKENS) && sessionTimeout) {
			return JSON.parse(localStorage.getItem(STORAGE.AUTHENTICATION_TOKENS));
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);
		return null;
	}

	return JSON.parse(localStorage.getItem(STORAGE.AUTHENTICATION_TOKENS));
};

/* Store the authentication token in stroage */
export const setAuthenticationTokens = tokens => {
	const expiryTime = new Date();
	expiryTime.setHours(expiryTime.getHours() + 3);
	localStorage.setItem(STORAGE.TOKEN_EXPIRY_TIMESTAMP, JSON.stringify(expiryTime.getTime()));
	localStorage.setItem(STORAGE.AUTHENTICATION_TOKENS, JSON.stringify(tokens));
};

/* Remove the authentication token from storage */
export const removeAuthenticationTokens = () => {
	localStorage.clear();
};

/* Check is application is runnig dev or prod mode */
export const isDevelopmentMode = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
