import { Component } from 'react';
import PropTypes from 'prop-types';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasError: false,
			clearCache: false
		};

		this.reloadPage = this.reloadPage.bind(this);
		this.clearResourceCache = this.clearResourceCache.bind(this);
	}

	componentDidCatch(error, errorInfo) {
		// eslint-disable-next-line no-console
		console.error('Uncaught error:', error, errorInfo);
	}

	/**
	 * Static method get error state
	 */
	// eslint-disable-next-line class-methods-use-this
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, clearCache: true };
	}

	/**
	 * Handle the retry page loading
	 */
	reloadPage() {
		this.setState({ clearCache: true });

		this.clearResourceCache()
			// eslint-disable-next-line no-console
			.then(() => console.warn('==== Cleared the resource cache ==='))
			.finally(() => window.location.reload(true));
	}

	/**
	 * Method for handling resource caches
	 */
	// eslint-disable-next-line class-methods-use-this
	clearResourceCache() {
		return new Promise((resolve, reject) => {
			caches
				.open('static-resources')
				.then(cache => {
					cache.keys().then(keys => {
						keys.forEach(request => cache.delete(request));
						resolve(true);
					});
				})
				.catch(err => reject(err));
		});
	}

	render() {
		const style = { width: '100%', height: '100%', display: 'grid', alignItems: 'center', justifyItems: 'center' };
		// TODO: map the proper color variable
		const linkStyle = { color: 'rgb(var(--secondary-6-main))', cursor: 'pointer' };
		const { hasError, clearCache } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<div style={style} role='button' aria-hidden='true' onClick={this.reloadPage}>
					{clearCache ? (
						<span className='kulavai-h4'>Fetching new content...</span>
					) : (
						<span className='kulavai-h4'>
							New content is available. Click to <span style={linkStyle}>refresh.</span>
						</span>
					)}
				</div>
			);
		}

		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired
};
