/* eslint-disable no-console */
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Divider, notification, Popover, Tooltip } from 'antd';
import jwt from 'jsonwebtoken';
import * as Msal from 'msal';
import { GoogleLogout } from 'react-google-login';

import { StoreContext } from '../../../Store.context';
import { useFirstRender } from '../../hooks';
import { STORE_ACTIONS, STORE_KEYS } from '../../constants';
import {
	AuthenticationStore,
	onAuthenticationChange$,
	onUserAvatarChange$,
	onUserRoleChange$,
	onLogoutChange$,
	onEmailVerified$
} from '../authentication-route/AuthenticationRoute';
import { Icon } from '../icon/Icon';
// import { PageLoader } from '../page-loader/PageLoader';
import { CobHeaderService } from './cob.header.service';
import { getAuthenticationTokens } from '../../helpers';
import './cob.header.scss';
// import { GuidanzToast } from '../guidanz-toast/GuidanzToast';

export const CobHeader = () => {
	const { dispatch } = useContext(StoreContext);
	// Component hooks instance declarations
	const history = useHistory();
	const { path } = useRouteMatch();

	// const googleLogout = useGoogleLogout({});

	// States for the component
	const [isUserLoggedIn, setIsUserLoggedIn] = useState(AuthenticationStore.isLoggedIn);
	// const [isLoading, setisLoading] = useState(false);
	const [userName, setUserName] = useState();
	const [userAvatar, setUserAvatar] = useState();
	const [currentUserDetails, setCurrentUserDetails] = useState({});
	const [isPopOverVisible, setIsPopOverVisible] = useState(false);
	const [isEmailVerified, setIsEmailVerified] = useState(false);
	const [thirdPartyAuth, setThirdPartyAuth] = useState();

	useFirstRender(() => {
		if (token) {
			AuthenticationStore.login(token);
			setIsUserLoggedIn(true);
			getCurrentUserInfo(true);
		} else {
			getCurrentUserInfo(isUserLoggedIn);
		}
	});

	useEffect(() => {
		// Emitter to notify when there is a change in an authentication
		onAuthenticationChange$.subscribe(isLoggedIn => {
			setIsUserLoggedIn(isLoggedIn);
			getCurrentUserInfo(isLoggedIn);
		});

		onLogoutChange$.subscribe(isLoggedOut => {
			if (isLoggedOut) {
				// GuidanzToast.error({ description: 'Token Expired' });
				history.push('/authentication/signin');
			}
		});

		// Emitter to notify when there is change in an user avatar
		onUserAvatarChange$.subscribe(imageUrl => {
			setUserAvatar(imageUrl);
			dispatch({ type: STORE_ACTIONS.USER_INFO, [STORE_ACTIONS.USER_INFO]: { imageUrl } });
		});

		// Unsubscribe all the listeners
		return () => subscriptions.forEach(s => s.unsubscribe());
	}, [onAuthenticationChange$, onLogoutChange$, onUserAvatarChange$]);

	// Local state maintenance
	const subscriptions = [];
	const token = new URLSearchParams(useLocation().search).get('token');

	/* MSAL configuration */
	const msalConfig = {
		auth: {
			clientId: process.env.REACT_APP_MS_CLIENT_ID,
			redirectUri: process.env.REACT_APP_REDIRECT_UI_LOCAL
		}
	};

	const msalInstance = new Msal.UserAgentApplication(msalConfig);

	/* Get current user information from REST api */
	const getCurrentUserInfo = isLoggedIn => {
		// Get the user info only, if logged in
		if (isLoggedIn) {
			const authenticationTokens = getAuthenticationTokens();
			// eslint-disable-next-line max-len
			const authType = jwt.decode(authenticationTokens)?.thirdPartyAuth;
			setThirdPartyAuth(authType);
			// Is user verified is email?
			// setisLoading(true);
			CobHeaderService.isEmailVerified()
				.then(res => {
					onEmailVerified$.next(!res.Data.EmailVerified);
					setIsEmailVerified(res.Data.EmailVerified);
					if (res?.Data?.CreatePwdEmailSent) {
						// history.push('/home');
						if (localStorage.getItem('loginType') && localStorage.getItem('loginType') === 'oAuth') {
							notification.info({
								message: 'Mail Verification',
								description: `Email has sent to ${res.Data.WorkEmail} to create an password`
							});
							localStorage.removeItem('loginType');
						}
					}
					if (res?.Data && !res?.Data?.EmailVerified) {
						history.push('/home');
					}
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.error(err);
					// setisLoading(false);
					// onEmailVerified$.unsubscribe();
				});
			CobHeaderService.getUserProfile()
				.then(response => {
					if (response?.Data) {
						dispatch({ type: STORE_ACTIONS.USER_INFO, [STORE_KEYS.USER_INFO]: response?.Data });
						setCurrentUserDetails(response?.Data);
						setUserName(`${response?.Data.FirstName}`);
						setUserAvatar(response?.Data.ImageUrl);
						onUserRoleChange$.next(response?.Data.UserRole);
						// setisLoading(false);
					}
				})
				.catch(error => {
					history.push('/authentication/signin');
					console.error(error);
					// setisLoading(false);
					// onUserRoleChange$.unsubscribe();
				});
		} else {
			// history.push('/authentication/signin');
		}
	};

	/* Navigate it into profile page */
	const onViewProfile = () => {
		dispatch({ type: STORE_ACTIONS.APP_GO_BACK_URL, [STORE_ACTIONS.APP_GO_BACK_URL]: path });
		history.push('/home/profile');
	};

	/* Logout the user and navigate it into the authentication page */
	const onLogout = () => {
		if (thirdPartyAuth === 'Microsoft') {
			msalInstance.logout();
		}
		setIsPopOverVisible(false);
		AuthenticationStore.logout();
		history.push('/authentication/signin');
	};

	/* Navigate to the home module when home gets clicked */
	const onClickHome = () => {
		history.push('/home');
	};

	// Profile menu elements
	const profileMenu = (
		<div className='profile-menu'>
			<div className='menu-avatar'>
				<div className='avatar-image'>
					{userAvatar ? (
						<img src={userAvatar} alt='Current Org' />
					) : (
						<span className='fallback'>
							{' '}
							{currentUserDetails.FirstName?.[0] + currentUserDetails.LastName?.[0]}{' '}
						</span>
					)}
				</div>
				<div className='avatar-details'>
					<h5 className='username'>{`${currentUserDetails.FirstName} ${currentUserDetails.LastName}`}</h5>
					<h5 className='email'>{currentUserDetails.WorkEmail}</h5>
					<ul>
						{isEmailVerified && (
							<li
								aria-hidden='true'
								onClick={() => {
									setIsPopOverVisible(false);
									onViewProfile();
								}}
							>
								View Profile
							</li>
						)}
						{thirdPartyAuth === 'Google' ? (
							<li>
								<GoogleLogout
									clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
									buttonText='Logout'
									onLogoutSuccess={onLogout}
									onFailure={e => console.log(e)}
									render={renderProps => (
										<span aria-hidden role='button' onClick={renderProps.onClick}>
											Sign out
										</span>
									)}
								/>
							</li>
						) : (
							<li aria-hidden='true' onClick={onLogout}>
								Sign out
							</li>
						)}
					</ul>
				</div>
			</div>
			<Divider className='profile-divider' />
			<div className='menu-footer'>
				<ul>
					<li>
						<a className='site-url' href='https://www.skedler.com/about/' target='_blank' rel='noreferrer'>
							About
						</a>
					</li>
					<li>
						<a
							className='site-url'
							href='https://www.skedler.com/privacy-policy/'
							target='_blank'
							rel='noreferrer'
						>
							Privacy Policy
						</a>
					</li>
					<li>
						<a
							className='site-url'
							href='https://www.skedler.com/terms-of-use/'
							target='_blank'
							rel='noreferrer'
						>
							Terms of Service
						</a>
					</li>
				</ul>
			</div>
		</div>
	);

	/* Render the JSX elements */
	return (
		<>
			{/* {isLoading && <PageLoader />} */}
			{isUserLoggedIn && (
				<header className='cob-header'>
					<section className='system-bar'>
						<div className='logo-block' role='button' aria-hidden='true' onClick={onClickHome}>
							<Icon name='skedlerColor' width={200} height={60} />
						</div>

						<nav className='navbar'>
							<ul>
								<li>
									<a
										className='site-url'
										href='https://www.skedler.com/documentation/'
										target='_blank'
										rel='noreferrer'
									>
										Documentation
									</a>
								</li>
								<li>
									Need Help?&nbsp;
									<a
										className='site-url'
										href='https://www.skedler.com/contact/'
										target='_blank'
										rel='noreferrer'
									>
										Contact us
									</a>
								</li>
								<li className='less-margin-left'>
									<Divider orientation='center' type='vertical' />
								</li>
								<li>
									<Popover
										overlayClassName='header-profile-menu-popover'
										placement='bottomRight'
										content={profileMenu}
										trigger='click'
										visible={isPopOverVisible}
										onVisibleChange={isVisible => setIsPopOverVisible(isVisible)}
									>
										{userAvatar ? (
											<Tooltip title={userName}>
												<span className='avatar'>
													<img src={userAvatar} alt='Avatar' />
												</span>
											</Tooltip>
										) : (
											<Tooltip title={userName}>
												{currentUserDetails?.FirstName ? (
													<span className='avatar user-name'>
														{currentUserDetails?.FirstName?.[0] +
															currentUserDetails?.LastName?.[0]}
													</span>
												) : (
													<span className='avatar user-name' />
												)}
											</Tooltip>
										)}
									</Popover>
								</li>
							</ul>
						</nav>
					</section>
				</header>
			)}
		</>
	);
};
