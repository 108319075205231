import React from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_NAMES } from '../constants';

export function GuidanzTableHeaderOptions(props) {
    // Props from the parent component
    const { children } = props;

    /* Render the JSX elements */
    return (
	<div className='table-header-options'>
		{children}
	</div>
    );
}

GuidanzTableHeaderOptions.propTypes = {
    children: PropTypes.node
};

GuidanzTableHeaderOptions.displayName = COMPONENT_NAMES.GuidanzTableHeaderOptions;
