/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_NAMES } from '../constants';

export function GuidanzTableHeader(props) {
    // Props from the parent component
    const { children } = props;

    // States for the component
    const [headerChildNodes, setHeaderChildNodes] = useState();

    /* Handler to render the table header view */
    useEffect(() => {
        if (!children) {
            console.warn(
                'No children present in <GuidanzTableHeader>'
            );
        } else if (Array.isArray(children)) {
            if (
                !children.every(t => t.type.displayName) ||
                children.every(t => (
                    (t.type.displayName === COMPONENT_NAMES.GuidanzTableTitle) &&
                    (t.type.displayName === COMPONENT_NAMES.GuidanzTableHeaderOptions)
                ))
            ) {
                console.warn(
                    'No <GuidanzTableTitle> or <GuidanzTableHeaderOptions> present in <GuidanzTableHeader>'
                );
            } else {
                setHeaderChildNodes(children);
            }
        } else if (
                !children.type.displayName ||
                ((children.type.displayName !== COMPONENT_NAMES.GuidanzTableTitle) &&
                    (children.type.displayName !== COMPONENT_NAMES.GuidanzTableHeaderOptions))
            ) {
                console.warn(
                    'No <GuidanzTableTitle> or <GuidanzTableHeaderOptions> present in <GuidanzTableHeader>'
                );
            } else {
                setHeaderChildNodes(children);
            }
    }, [children]);

    /* Render the JSX elements */
    return (
	<div className='table-header-wrapper'>
		{headerChildNodes}
	</div>
    );
}

GuidanzTableHeader.propTypes = {
    children: PropTypes.node
};

GuidanzTableHeader.displayName = COMPONENT_NAMES.GuidanzTableHeader;
