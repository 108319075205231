/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import './icon.scss';

export const Icon = props => {
	const { name, className, width, height, onClick } = props;
	return (
		<span className='icon-wrapper' role='button' aria-hidden='true'>
			{name ? (
				<SVG
					src={require(`../../../assets/icons/${name}.svg`).default}
					width={width}
					height={height}
					className={`iconSvg ${className}`}
					cacheRequests
					loader={<span>...</span>}
					onClick={onClick}
					// eslint-disable-next-line no-console
					onError={error => console.error(name, { Error: error.message })}
					noValidate
				>
					<SVG src={require('../../../assets/icons/warning.svg').default} width={200} height='auto' />
				</SVG>
			) : (
				<span>{name}</span>
			)}
		</span>
	);
};

Icon.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	onClick: PropTypes.func
};

Icon.defaultProps = {
	className: '',
	width: 24,
	height: 24,
	onClick: () => {}
};
