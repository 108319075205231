import { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import StoreController from './Store.Provider';
import { CobHeader, ErrorBoundary, PageLoader } from './library/components';

const Cob = lazy(() => import('./views/Cob'));

function Routes() {
	return (
		<BrowserRouter basename='app'>
			<ErrorBoundary>
				<Suspense fallback={<PageLoader />}>
					<StoreController>
						<>
							<CobHeader />
							<Switch>
								<Route path='/' render={() => <Cob />} />
							</Switch>
						</>
					</StoreController>
				</Suspense>
			</ErrorBoundary>
		</BrowserRouter>
	);
}

export default Routes;
