/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../icon/Icon';
import { SORTING_ORDER, COMPONENT_NAMES } from '../constants';
import { onClickHead$ } from '../events';

export function GuidanzTableHead(props) {
	// Props from the parent component
	const { children } = props;

	// States for the component
	const [tableHeads, setTableHeads] = useState([]);

	/* Handler to convert the nodes as array */
	useEffect(() => {
		const heads = [];

		children.forEach(child => {
			heads.push({
				children: child.props.children,
				columnKey: child.props.columnKey,
				isSortable: child.props.isSortable,
				sortingOrder: SORTING_ORDER.NONE,
				sort: child.props.sort
			});
		});

		setTableHeads(heads);

		// eslint-disable-next-line
	}, []);

	/* Check whether the column is sortable or not */
	const isSortable = isSortable => typeof isSortable === 'undefined' || isSortable;

	/* Handler for toggle the sorting order */
	const onClickHead = columnKey => {
		setTableHeads(tableHeads => {
			const head = tableHeads.find(t => t.columnKey === columnKey);
			switch (head.sortingOrder) {
				case SORTING_ORDER.NONE:
					head.sortingOrder = SORTING_ORDER.ASCENDING;
					break;
				case SORTING_ORDER.ASCENDING:
					head.sortingOrder = SORTING_ORDER.DESCENDING;
					break;
				case SORTING_ORDER.DESCENDING:
					head.sortingOrder = SORTING_ORDER.ASCENDING;
					break;
				default:
					break;
			}

			// Notify the sorting event
			onClickHead$.next({
				columnKey: head.columnKey,
				sortingOrder: head.sortingOrder,
				sort: head.sort
			});

			return [...tableHeads];
		});
	};

	/* Render the JSX elements */
	return tableHeads.map((head, i) => (
		<th key={head.columnKey + i}>
			<span
				role='columnheader'
				aria-hidden='true'
				className={`column-field ${isSortable(head.isSortable) && head.children ? 'sortable' : ''}`}
				onClick={() => onClickHead(head.columnKey)}
			>
				{head.children}
				{isSortable(head.isSortable) && head.children ? (
					<span
						className={`sort-icon ${
							head.sortingOrder === SORTING_ORDER.ASCENDING ||
							head.sortingOrder === SORTING_ORDER.DESCENDING
								? 'active'
								: ''
						}`}
					>
						<Icon
							name={`${
								head.sortingOrder === SORTING_ORDER.DESCENDING ||
								head.sortingOrder === SORTING_ORDER.NONE
									? 'arrowDown'
									: 'arrowUp'
							}`}
						/>
					</span>
				) : null}
			</span>
		</th>
	));
}

GuidanzTableHead.propTypes = {
	children: PropTypes.node
};

GuidanzTableHead.displayName = COMPONENT_NAMES.GuidanzTableHead;
