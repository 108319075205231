/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import jwt from 'jsonwebtoken';

import { onAuthenticationChange$, onLogoutChange$ } from '../components/authentication-route/AuthenticationRoute';
import { GuidanzToast } from '../components/guidanz-toast/GuidanzToast';
import { getAuthenticationTokens } from '../helpers';
import { config } from '../../config';

/* Check http response status */
const checkStatus = async response => {
	const error = {};
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	const res = await response.json();

	if (res.message !== '') {
		error.message = res.Message;
		error.error_code = res.StatusCode;
		if (res.Message !== 'Token Expired' && res.message !== 'Invalid Token') {
			GuidanzToast.error({ description: res.Message });
			onAuthenticationChange$.next(false);
		} else {
			onLogoutChange$.next(true);
		}
		return Promise.reject(error);
	}

	try {
		// eslint-disable-next-line no-console
		console.warn('Network Request Failed', {
			url: response.url,
			status: response.status,
			statusText: response.statusText
		});

		if (response.headers.get('Content-Type') === 'application/json') {
			return response.json().then(json => Promise.reject(json));
		}

		return Promise.reject(new Error('Unhandled server error'));
	} catch (err) {
		// eslint-disable-next-line no-console
		error.status = response.statusText;
		console.error(err);
		return Promise.reject(error);
	}
};

/* Combine url with query params */
const serializeQueryParams = (url, params) => {
	let queryString = url.lastIndexOf('?') !== -1 ? '&' : '?';

	// eslint-disable-next-line no-restricted-syntax
	for (const key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			queryString += `${key}=${encodeURIComponent(params[key])}`;
		}
	}

	return `${url}${queryString}`;
};

/* Create the http request based on options */
const createRequest = (url, options) => {
	let headers = {};

	if (
		(options?.header && !Object.prototype.hasOwnProperty.call(options.header, 'Content-Type')) ||
		!Object.prototype.hasOwnProperty.call(options, 'header')
	) {
		headers = { 'Content-Type': 'application/json', ...(options.header || {}) };
	}

	const authenticationTokens = getAuthenticationTokens();

	if (authenticationTokens) {
		headers.Authorization = authenticationTokens;
		headers['X-Csrf-Token'] = jwt.decode(authenticationTokens).Csrf;
	}

	headers['Account-ID'] = 'AC_88BC882731';

	if (options?.query) {
		url = serializeQueryParams(url, options.query);
	}

	const request = {
		method: options?.method,
		headers,
		credentials: options?.isCORS ? 'include' : 'same-origin' // Mandatory for sending cookie along with request
	};

	if (options?.data) {
		request.body = typeof options.data !== 'string' ? JSON.stringify(options.data) : options.data;
	}

	if (options?.body) {
		request.body = options.body;
	}

	if (options?.signal) {
		request.signal = options.signal;
	}

	return new Request(url, request);
};

/* Send request to the server */
const sendRequest = (url, options) => {
	url = `${config().API_SERVER_URL}${url.replace(/\/$/, '')}`;
	const request = createRequest(url, options);
	const promise = fetch(request)
		.then(checkStatus)
		.then(response => response.json())
		.catch(err => Promise.reject(err));

	return promise;
};

export const HttpService = {
	get: (url, options = {}) => {
		options.method = 'GET';
		return sendRequest(url, options);
	},
	post: (url, options = {}) => {
		options.method = options.method || 'POST';
		return sendRequest(url, options);
	},
	update: (url, options = {}) => {
		options.method = 'PUT';
		return sendRequest(url, options);
	},
	patch: (url, options = {}) => {
		options.method = 'PATCH';
		return sendRequest(url, options);
	},
	delete: (url, options = {}) => {
		options.method = 'DELETE';
		return sendRequest(url, options);
	}
};
