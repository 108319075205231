import { useReducer } from 'react';
import PropTypes from 'prop-types';

import { StoreContext, initialStore } from './Store.context';
import { STORAGE, STORE_ACTIONS, STORE_KEYS } from './library';

const StoreProvider = ({ reducer, initState, children }) => {
	const [state, dispatch] = useReducer(reducer, initState);
	const value = { state, dispatch };

	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

const StoreController = ({ children }) => {
	const storeReducer = (state, action) => {
		switch (action.type) {
			case STORE_ACTIONS.USER_INFO:
				// eslint-disable-next-line max-len
				localStorage.setItem(STORAGE.USER_INFO, JSON.stringify({ ...state.userInfo, ...action.userInfo }));
				return {
					...state,
					[STORE_KEYS.USER_INFO]: { ...state.userInfo, ...action.userInfo }
				};
			case STORE_ACTIONS.APP_GO_BACK_URL:
				return {
					...state,
					[STORE_KEYS.APP_GO_BACK_URL]: action.appGoBackURL
				};
			case STORE_ACTIONS.CART_INFO:
				return {
					...state,
					[STORE_KEYS.CART_INFO]: action.cartInfo
				};
			default:
				return state;
		}
	};

	return (
		<StoreProvider reducer={storeReducer} initState={initialStore}>
			{children}
		</StoreProvider>
	);
};

StoreController.propTypes = {
	children: PropTypes.element.isRequired
};

StoreProvider.propTypes = {
	reducer: PropTypes.func.isRequired,
	initState: PropTypes.shape({
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		WorkEmail: PropTypes.string,
		Organization: PropTypes.string,
		UserRole: PropTypes.string,
		IsAccountAdmin: PropTypes.bool,
		AdminChangeInvited: PropTypes.bool,
		NewAdminInvited: PropTypes.string,
		ThirdPartyAuthentication: PropTypes.string,
		ImageUrl: PropTypes.string,
		UserState: PropTypes.string,
		Source: PropTypes.string
	}),
	children: PropTypes.element.isRequired
};

export default StoreController;
