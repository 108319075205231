import './page-loader.scss';
import PropTypes from 'prop-types';

export function PageLoader({ styles }) {
	return (
		<div className='cob-app-page-loader' style={styles}>
			<div className='cob-app-page-loader-container'>
				<div className='cob-app-page-loader-wrapper'>
					<div className='page-loader-bar' />
					<div className='page-loader-bar' />
					<div className='page-loader-bar' />
					<div className='page-loader-bar' />
					<div className='page-loader-bar' />
					<div className='page-loader-ball' />
				</div>
			</div>
		</div>
	);
}

PageLoader.propTypes = {
	styles: PropTypes.objectOf(PropTypes.object)
};

PageLoader.defaultProps = {
	styles: {}
};
