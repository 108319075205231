/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Empty, Checkbox } from 'antd';

import { SkeletonLoader } from '../skeleton-loader/SkeletonLoader';
import { COMPONENT_NAMES, SORTING_ORDER, PAGINATION } from './constants';
import { GuidanzTableBatchActions } from './header/GuidanzTableBatchActions';
import { GuidanzTablePagination } from './pagination/GuidanzTablePagination';
import { onClickHead, onChangeSelectedRows$ } from './events';
import './guidanz-table.scss';

export function GuidanzTable(props) {
	// Props from the parent component
	const { children, rows, rowKey, className } = props;

	// States for the component
	const [tableRows, setTableRows] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [paginationData, setPaginationData] = useState();

	const [tableHeaderNodes, setTableHeaderNodes] = useState();
	const [tableBatchActionNodes, setTableBatchActionNodes] = useState();
	const [tableHeadNodes, setTableHeadNodes] = useState();
	const [tableRowNodes, setTableRowNodes] = useState();
	const [tablePaginationNodes, setTablePaginationNodes] = useState();

	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [isIndeterminate, setIsIndeterminate] = useState(false);

	// Local state maintenance
	const subscriptions = [];

	/* Handler for render the table based on childrens */
	useEffect(() => {
		if (!children) {
			console.warn('No children present in <GuidanzTable>');
		} else if (!Array.isArray(children)) {
			console.warn(
				'Needed head and row to render <GuidanzTable>, Single children can&apos;t be considered to render table'
			);
		} else {
			// Render table header, if it's present
			const tableHeader = children.find(t => t.type.displayName === COMPONENT_NAMES.GuidanzTableHeader);

			if (tableHeader) {
				setTableHeaderNodes(tableHeader);
			}

			// Render table batch actions block, if it's present
			const tableBatchActions = children.find(
				t => t.type.displayName === COMPONENT_NAMES.GuidanzTableBatchActions
			);

			if (tableBatchActions) {
				setTableBatchActionNodes(tableBatchActions);
			}

			// Render table head, if it's present
			const tableHead = children.find(t => t.type.displayName === COMPONENT_NAMES.GuidanzTableHead);

			if (tableHead) {
				setTableHeadNodes(tableHead);
			}

			// Render table row, if it's present
			const tableRow = children.find(t => t.type.displayName === COMPONENT_NAMES.GuidanzTableRow);

			if (tableRow) {
				setTableRowNodes(tableRow);
			}

			// Render table pagination, if it's present
			const tablePagination = children.find(t => t.type.displayName === COMPONENT_NAMES.GuidanzTablePagination);
			if (tablePagination) {
				setTablePaginationNodes(tablePagination);
			}
		}
	}, [children]);

	/* Reset the table to it's initial state, when rows get changed */
	useEffect(() => {
		// Update the table states to the initial state
		PAGINATION.totalItems = rows?.length;
		setPagination({ ...PAGINATION, currentPage: 1 }, true);

		setIsIndeterminate(false);
		setIsCheckedAll(false);
		setSelectedRows([]);

		// Emit the selected row list as empty
		if (tableBatchActionNodes?.props) {
			tableBatchActionNodes.props.onChange([]);
			onChangeSelectedRows$.next([]);
		}

		// Listen the table events
		subscriptions.push(
			onClickHead().subscribe(({ columnKey, sortingOrder, sort }) => {
				onSortRow(columnKey, sortingOrder, sort);
			})
		);

		// Unsubscribe the listeners
		return () => subscriptions.forEach(s => s.unsubscribe());

		// eslint-disable-next-line
	}, [rows]);

	/* Sort the table rows based on order */
	const onSortRow = (key, order, sort) => {
		function compare(a, b) {
			if (
				(!key?.includes('Metadata') &&
					!key?.includes('LicenseMetadata') &&
					!Object.prototype.hasOwnProperty.call(a, key)) ||
				(!key?.includes('Metadata') &&
					!key?.includes('LicenseMetadata') &&
					!Object.prototype.hasOwnProperty.call(b, key))
			) {
				return 0;
			}
			// eslint-disable-next-line no-nested-ternary
			const valA = sort ? sort(a, key) : typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
			// eslint-disable-next-line no-nested-ternary
			const valB = sort ? sort(b, key) : typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
			let comparison = 0;
			if (valA > valB) {
				comparison = 1;
			} else if (valA < valB) {
				comparison = -1;
			}

			return order === SORTING_ORDER.DESCENDING ? comparison * -1 : comparison;
		}

		rows.sort(compare);
		setPaginationData(prev => {
			prev.currentPage = 1;
			prev.lastPage = 0;
			setTimeout(() => {
				setPagination(prev);
			});
			return prev;
		});
	};

	/* Handler to toggle select specific row */
	const onSelectRowClick = row => {
		const results = [];

		row.isChecked = !row.isChecked;
		tableRows.forEach(row => {
			if (row.isChecked) {
				results.push(row[rowKey]);
			}
		});

		setIsIndeterminate(!!results.length && results.length < tableRows.length);
		setIsCheckedAll(results.length === tableRows.length);
		setSelectedRows(results);

		// Emit the selected row list
		onChangeSelectedRows$.next(results);
		tableBatchActionNodes.props.onChange(results);
	};

	/* Handler to toggle select all row */
	const onSelectAllRowClick = isChecked => {
		const results = [];

		tableRows.forEach(row => {
			row.isChecked = isIndeterminate || !row.isChecked;

			if (row.isChecked) {
				results.push(row[rowKey]);
			}
		});

		if (isIndeterminate) {
			setIsIndeterminate(false);
		}
		setIsCheckedAll(isIndeterminate || !isChecked);
		setSelectedRows(results);

		// Emit the selected row list
		onChangeSelectedRows$.next(results);
		tableBatchActionNodes.props.onChange(results);
	};

	/* Handler for clear the all selected rows */
	const onClearAll = () => {
		tableRows.forEach(row => {
			row.isChecked = false;
		});

		setIsIndeterminate(false);
		setIsCheckedAll(false);
		setSelectedRows([]);

		// Emit an empty list
		tableBatchActionNodes.props.onChange([]);
	};

	/* Handler for on mouse enter on row */
	const onRowMouseEnter = row => {
		if (tableRowNodes?.props?.onRowEnter) {
			tableRowNodes.props.onRowEnter(row);
		}
	};

	/* Handler for on mouse leave on row */
	const onRowMouseLeave = row => {
		if (tableRowNodes?.props?.onRowLeave) {
			tableRowNodes.props.onRowLeave(row);
		}
	};

	/* Handler for on click row */
	const onClickRow = row => {
		if (tableRowNodes?.props?.onClickRow) {
			tableRowNodes.props.onClickRow(row);
		}
	};

	/* Handler for set the table rows based on pagination indexes */
	const setPagination = pagination => {
		pagination.lastPage = Math.ceil(pagination.totalItems / pagination.itemsPerPage);
		if (pagination.currentPage > Math.ceil(pagination.totalItems / pagination.itemsPerPage)) {
			pagination.currentPage = Math.ceil(pagination.totalItems / pagination.itemsPerPage);
		}

		const startIndex = (pagination.currentPage - 1) * pagination.itemsPerPage;
		let endIndex = startIndex + pagination.itemsPerPage;

		if (endIndex > rows?.length) {
			endIndex = rows?.length;
		}

		const data = rows?.slice(startIndex, endIndex);
		setPaginationData({ ...pagination });
		setTableRows(data);
	};

	/* Render the JSX elements */
	return (
		<div className={`guidanz-table-layout-wrapper ${className || ''}`}>
			{tableHeaderNodes}

			{tableBatchActionNodes && (
				<GuidanzTableBatchActions
					onChange={e => tableBatchActionNodes.props.onChange(e)}
					onClearAll={() => onClearAll()}
				>
					{tableBatchActionNodes.props.children}
				</GuidanzTableBatchActions>
			)}

			<div className={`guidanz-table-container ${selectedRows.length ? 'batch-actions-active' : ''}`}>
				<div className='guidanz-table-header'>
					<table className='guidanz-table'>
						<colgroup>
							{tableHeadNodes && tableBatchActionNodes && <col style={{ width: 58 }} />}
							{tableHeadNodes?.props?.children.map((child, index) => (
								<col key={`hcolumn${index}`} style={{ width: child.props.width || 160 }} />
							))}
						</colgroup>

						<thead>
							<tr>
								{tableHeadNodes && (
									<>
										{tableBatchActionNodes && (
											<th className='table-select'>
												<div className='checkbox-wrapper'>
													<Checkbox
														disabled={!tableRows?.length}
														checked={isCheckedAll}
														indeterminate={isIndeterminate}
														onClick={() => onSelectAllRowClick(isCheckedAll)}
													/>
												</div>
											</th>
										)}

										{tableHeadNodes}
									</>
								)}
							</tr>
						</thead>
					</table>
				</div>
				<div className='guidanz-table-body'>
					<table className='guidanz-table'>
						<colgroup>
							{tableHeadNodes && tableBatchActionNodes && <col style={{ width: 58 }} />}
							{tableHeadNodes?.props?.children.map((child, i) => (
								<col key={`bcolumn${i}`} style={{ width: child.props.width || 160 }} />
							))}
						</colgroup>

						<tbody>
							{tableRows && tableRows.length ? (
								tableRows.map((row, index) => (
									<tr
										key={row[rowKey] + index.toString()}
										onMouseEnter={() => onRowMouseEnter(row)}
										onMouseLeave={() => onRowMouseLeave(row)}
										onClick={() => onClickRow(row)}
										className={`${row.isChecked ? 'is-selected' : ''}`}
									>
										{tableRowNodes?.props?.render && (
											<>
												{tableBatchActionNodes && (
													<td className='table-select'>
														{row[rowKey] ? (
															<div className='checkbox-wrapper'>
																<Checkbox
																	checked={row.isChecked}
																	onClick={() => onSelectRowClick(row)}
																/>
															</div>
														) : (
															<SkeletonLoader />
														)}
													</td>
												)}

												{tableRowNodes.props.render(row)}
											</>
										)}
									</tr>
								))
							) : (
								<tr>
									<td
										colSpan={`${
											tableHeadNodes?.props?.children.length + (tableBatchActionNodes ? 1 : 0)
										}`}
									>
										<div className='guidanz-table-placeholder'>
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{tablePaginationNodes && (
				<GuidanzTablePagination
					paginationData={{ ...paginationData }}
					onPaginationChange={value => setPagination(value)}
				>
					{tablePaginationNodes?.props?.children}
				</GuidanzTablePagination>
			)}
		</div>
	);
}

GuidanzTable.propTypes = {
	children: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	rows: PropTypes.array,
	rowKey: PropTypes.string,
	className: PropTypes.string
};

GuidanzTable.displayName = COMPONENT_NAMES.GuidanzTable;
