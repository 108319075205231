import { HttpService } from '../../common';

const version = 1;

export const CobHeaderService = {
	isEmailVerified: () => HttpService.get(`/api/${version}/user/isEmailVerified`),

	getUserProfile: () => HttpService.get(`/api/${version}/user/getUserProfile`),

	sendNotification: userDetails => HttpService.post(`/api/${version}/user/sendNotification`, { data: userDetails })
};
